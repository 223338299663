import React from 'react'
import PropTypes from 'prop-types';
import '../../src/mess'
import PasswordMeter from '../PasswordMeter'

class PasswordWidget extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      password: '',
      password_confirmation: ''
    }
  }

  errorFor(field) {
    if(this.props.errors && this.props.errors[0]) {
      return this.props.errors[0][field];
    }
  }

  handleChange(event, attr_name) {
    const object = {}

    object[attr_name] = event.target.value
    Mess.publish(`${attr_name}_change`, event.target.value)
    this.setState(object);
  }

  render() {
    const passwordErrorText = this.errorFor('password')
    const passwordConfirmationErrorText = this.errorFor('password_confirmation')

    const passwordErrorClass = passwordErrorText ? 'red-border' : ''
    const passwordConfirmationErrorClass = passwordConfirmationErrorText ? 'red-border' : ''

    return (
      <div className="step2-content">
        <div className="step2-password">
          <h3 className="password-title">Save Your Details</h3>
          <h4 className="password-title-light">Create an account to access your downloads and future updates</h4>
            <div className="password-box">
              <div>
                <input type="hidden" name="user[email]" id="user_email" value={ this.props.email }/>
                <div>
                  <label className="label-password" htmlFor="user_password">
                    Password: <span className="red-asterisk">*</span>
                  </label>
                  <i className="icon-blue-info"></i>
                  {
                    passwordErrorText && (
                      <div className="error-container">
                        <div className="error-text">
                          { passwordErrorText }
                        </div>
                      </div>
                    )
                  }
                  <div className="password-input-wrapper">
                    <input
                          className={"input-password " + passwordErrorClass}
                          type="password"
                          value={this.state.password}
                          name="user[password]"
                          id="user_password"
                          autoComplete="off"
                          onChange={(event) => this.handleChange(event, 'password')} />
                    <PasswordMeter password={this.state.password}/>
                  </div>
                </div>
                <div>
                  <label className="label-password" htmlFor="user_password_confirmation">
                    Password Confirmation: <span className="red-asterisk">*</span>
                  </label>
                  {
                    passwordConfirmationErrorText && (
                      <div className="error-container">
                        <div className="error-text">
                          { passwordConfirmationErrorText }
                        </div>
                      </div>
                    )
                  }
                  <div className="password-input-wrapper">
                    <input
                          className={"input-password " + passwordConfirmationErrorClass}
                          type="password"
                          value={this.state.password_confirmation}
                          name="user[password_confirmation]"
                          id="user_password_confirmation"
                          autoComplete="off"
                          onChange={(event) => this.handleChange(event, 'password_confirmation')} />
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    )
  }
}

PasswordWidget.propTypes = {
  email: PropTypes.string.isRequired,
  errors: PropTypes.array.isRequired,
  needsPayment: PropTypes.bool.isRequired
}
export default PasswordWidget
