import React from "react";
import csrfToken from '../../src/csrf_token'
import { setFallbackImage } from "../../src/actions/setFallbackImage";

export default function GiftItem({
  product,
  productUrl,
  frId,
  fallbackImageSrc,
  alreadyInCartProducts,
  recommendationsService,
  frMeta,
}) {
  const onImageError = (e) => setFallbackImage(e, fallbackImageSrc);

  const alreadyInCartProductIds = alreadyInCartProducts.map((item) =>
    item.course !== undefined ? item.course.course_id : item.product.product_id
  );
  const isAlreadyInCart = alreadyInCartProductIds.includes(product.product_id);

  const freshRelevanceAdd = (url, freshRelevanceId) =>
    window.$TB.trackClick(frMeta, url, freshRelevanceId);

  const addToCart = () => {
    if (recommendationsService === "fresh_relevance")
      freshRelevanceAdd(productUrl, frId);
  };

  return (
    <div className="CartGiftItemModal-item" key={product.product_id}>
      <a href={productUrl} className="CartGiftItemModal-item-img">
        <img
          src={product.image_url || fallbackImageSrc}
          alt={product.image_description}
          onError={onImageError}
        />
      </a>

      <div className="CartGiftItemModal-item-info">
        <a className="CartGiftItemModal-item-info--name" href={productUrl}>
          {product.name}
        </a>
        <a
          href={product.manufacturer.link_to}
          className="CartGiftItemModal-item-info--manufacturer"
        >
          {product.manufacturer.name}
        </a>
        <a
          href={product.category.link_to}
          className="CartGiftItemModal-item-info--category"
        >
          {product.category.name}
        </a>
      </div>

      <div className="CartGiftItemModal-item-price">
        <div className="CartGiftItemModal-item-price">{product.sell_price}</div>
      </div>

      <div className="CartGiftItemModal-item-buttons">
        {isAlreadyInCart ? (
          <div className="CartProductItem-recommendedItemInWishlist">
            In Cart
          </div>
        ) : (
          <div className="CartGiftItemModal-item-buttons">
            <form
              action="orders/add_to_cart"
              method="post"
              onSubmit={() => addToCart()}
            >
              <input type="hidden" name="authenticity_token" value={csrfToken()} />
              <input type="hidden" name="id" value={product.product_id} />
              <button
                type="submit"
                className="CartGiftItemModal-item-buttons-add"
                title="Add product to cart"
              >
                Add to Cart
              </button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
}
