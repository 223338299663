import React from 'react'

class UserOrderItem extends React.Component {
  render() {
      const { order_item: orderItem } = this.props
      const product = orderItem.custom_bundle_product || orderItem.product || orderItem.course

      return (
          <tr className="userorder-item">
              <th><a href={ product.link_to } title={ product.name }>{ product.name }</a></th>
              <td>{ orderItem.sell_price }</td>
          </tr>
      )
  }
}

export default UserOrderItem
