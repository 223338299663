/* eslint no-console:0 */
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import Choices from 'choices.js'
window.Choices = Choices
import './pib'

window.$ = require("jquery")
window._AutofillCallbackHandler = window._AutofillCallbackHandler || function(){}; // workaround for unrecognized call

import {} from 'jquery-ujs'

var getCookie = function (name) {
  var value = '; ' + document.cookie;
  var parts = value.split('; ' + name + '=');
  if (parts.length === 2) return parts.pop().split(';').shift();
  return false;
}

var loadPapParams = function() {
  var params = new URLSearchParams(window.location.search);
  var papParams = ['a_aid', 'a_bid', 'a_cid', 'chan'];
  for (var i = 1; i < 6; i++) { papParams.push('data' + parseInt(i)); }

  papParams.forEach(function(paramName) {
    if (params.get(paramName) && !getCookie(paramName))
      document.cookie = paramName + "=" + params.get(paramName) + "; path=/";
  });
}

window.addEventListener('load', function() {
  loadPapParams();
  setupFrFallbacks()
});

var componentRequireContext = require.context("components", true);
require.context("images", true);
var ReactRailsUJS = require("react_ujs");

ReactRailsUJS.useContext(componentRequireContext);

const setupFrFallbacks = () => {
  document.querySelectorAll('.js-fr-placeholder').forEach((placeholder) => {
    placeholder.addEventListener('click', (event) => {
      if (event.target !== event.currentTarget) {
        return
      }

      event.preventDefault()

      window.location.href = placeholder.dataset.href
    })
  })
}
