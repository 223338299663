import React from 'react'
import PropTypes from 'prop-types'
import BuyBoxPrice from './BuyBoxPrice'
import BuyBoxBundleSize from './BuyBoxBundleSize'
import BuyBoxDiscountRow from './BuyBoxDiscountRow'
import BuyBoxVirtualBalanceWithRewards from './BuyBoxVirtualBalanceWithRewards'
import BuyBoxGiftsList from './BuyBoxGiftsList'
import CurrencyChanger from './CurrencyChanger'
import WishlistAdd from './WishlistAdd'
import BuyBoxAdd from './BuyBoxAdd'
import csrfToken from '../src/csrf_token'

class CustomBundleBuyBox extends React.Component {
  state = {
    custom_bundle: this.props.custom_bundle,
    currency: this.props.currency || this.props.custom_bundle.currency
  }

  handleCurrencyChanged = (currency) => {
    $.ajax({
      url: '/custom_bundles/' + this.props.custom_bundle.custom_bundle_id + '/price_data.js?currency=' + currency,
      dataType: 'json',
      headers: {
        "X-CSRF-Token": csrfToken()
      },
      success: function(data) {
        this.setState({custom_bundle: data})
      }.bind(this),
      error: function(xhr, status, err) {
        console.error(this.props.url, status, err.toString());
      }.bind(this)
    });

    this.setState({ currency: currency })
  }

  render() {
    const bundle = this.state.custom_bundle
    const clazz = `buybox ${bundle.is_on_sale && 'buybox-sale'}`

    return (
      <div className={ clazz }>
        {
          bundle.is_on_sale && <span className='saleBackgroundText'>sale</span>
        }

        <BuyBoxPrice
          with_vat={ bundle.with_vat }
          value={ bundle.sell_price }
          is_custom_bundle={true}
        />

        {
          bundle.is_on_sale && (
            <BuyBoxDiscountRow
              regularPrice={bundle.regular_price}
              discount={bundle.sale_discount}
            />
          )
        }

        <BuyBoxVirtualBalanceWithRewards
          virtualCashBalance={bundle.virtual_cash_balance}
          virtualCash={bundle.virtual_cash}
          rewardTokens={bundle.reward_tokens}
        />

        <div className='buybox-custom-bundle-package-row'>
          <BuyBoxBundleSize size={ bundle.bundle_size } />
          <CurrencyChanger
            currency={ this.state.currency }
            callback={ this.handleCurrencyChanged }
          />
        </div>

        <div className='buybox-actions'>
          <BuyBoxAdd
            item_id={ bundle.custom_bundle_id }
            in_cart={ bundle.in_cart }
            with_new_styles={ true }
            can_add_to_cart={ bundle.can_add_to_cart }
            rejection_description={ bundle.rejection_description }
            rejection_link={ bundle.rejection_link }
          />

          <BuyBoxGiftsList gifts={this.props.gifts} />

          <div className='buybox-links'>
            {
              !bundle.hidden && (
                <WishlistAdd item_id={ bundle.custom_bundle_id } with_new_styles={ true } />
              )
            }
          </div>
        </div>
      </div>
    )
  }
}

export default CustomBundleBuyBox
