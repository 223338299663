import React from 'react'
import PropTypes from 'prop-types';

class Address extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      first_name: this.props.address.first_name,
      last_name: this.props.address.last_name,
      company_name: this.props.address.company_name,
      vat_number: this.props.address.vat_number,
      address_1: this.props.address.address_1,
      address_2: this.props.address.address_2,
      postal_code: this.props.address.postal_code,
      city: this.props.address.city,
      province: this.props.address.province,
      country: this.props.address.country,
      phone_number: this.props.address.phone_number
    }

    this.handleChange = this.handleChange.bind(this)
    this.textInput = this.textInput.bind(this)
    this.renderStateField = this.renderStateField.bind(this)
    this.renderCountryField = this.renderCountryField.bind(this)
    this.statesList = this.statesList.bind(this)
  }

  validateProvince(country, province) {
    const province_item = this.statesList(country).find(item => item[0] == province)
    return (province_item ? province_item[1].name : province)
  }

  handleChange(event, attr_name) {
    const object = {}

    object[attr_name] = event.target.value

    this.setState(object)

    if (attr_name === 'country') {
      this.setState(
        { province: '' }
      )
    }
  }

  textInput(label_text, attr_name, attr_value) {
    const errorMessages = this.props.errors[attr_name]
    const inputClassName = (errorMessages !== undefined) ? 'error' : ''

    return (
      <p>
        <label htmlFor={`${this.props.kind}_${attr_name}`}>
          {label_text}:
        </label>

        <input placeholder={label_text}
               className={inputClassName}
               type="text"
               value={attr_value}
               name={`${this.props.addressOwner}[${this.props.kind}_info_attributes][${attr_name}]`}
               id={`${this.props.addressOwner}_${this.props.kind}_info_attributes_${attr_name}`}
               onChange={(event) => this.handleChange(event, attr_name)} />

        { (errorMessages !== undefined) && (
            <span className="formError" dangerouslySetInnerHTML={{ __html: _.unescape(errorMessages.join(', ')) }} />
        ) }
      </p>
    )
  }

  statesList(country) {
    switch (country) {
      case 'US':
        return this.props.usStatesList

        break
      case 'CA':
        return this.props.canadaStatesList

        break
      default:
        null
    }
  }

  renderStateField() {
    let field = null
    const country = this.state.country
    const provinceErrorMessages = this.props.errors['province']
    const inputClassName = (provinceErrorMessages !== undefined) ? 'error state-selector' : 'state-selector'

    if (country === 'US' || country === 'CA') {
      field = (
        <p>
          <label htmlFor={`${this.props.kind}_state`}></label>
          <span className="select">
            <select className={inputClassName}
                    onChange={(event) => this.handleChange(event, 'province')}
                    value={this.validateProvince(country, this.state.province)} name={`${this.props.addressOwner}[${this.props.kind}_info_attributes][province]`}
                    id={`${this.props.addressOwner}_${this.props.kind}_info_attributes_province`}>

              <option key='default' value=''>Choose state...</option>

              { _.map(this.statesList(country), (item) => {
                return <option key={item[0]} value={item[1].name}>{item[1].name}</option>
              }) }
            </select>
          </span>

          { (provinceErrorMessages !== undefined) && (
              <span className="formError">{provinceErrorMessages.join(', ')}</span>
          ) }
        </p>
      )
    } else {
      field = this.textInput('State', 'province', this.state.province)
    }

    return field
  }

  renderCountryField() {
    const countryErrorMessages = this.props.errors['country']
    const inputClassName = (countryErrorMessages !== undefined) ? 'error country-selector' : 'country-selector'

    return(
      <p>
        <label htmlFor={`${this.props.kind}_country`}></label>
        <span className="select">
          <select className={inputClassName}
                  onChange={(event) => this.handleChange(event, 'country')}
                  value={this.state.country} name={`${this.props.addressOwner}[${this.props.kind}_info_attributes][country]`}
                  id={`${this.props.addressOwner}_${this.props.kind}_info_attributes_country`}>

            <option key='default' value=''>Choose country...</option>

            { _.map(this.props.countriesList, (item) => {
                return <option key={item[0]} value={item[0]}>{item[1]}</option>
            }) }
          </select>
        </span>

        { (countryErrorMessages !== undefined) && (
            <span className="formError">{countryErrorMessages.join(', ')}</span>
        ) }
      </p>
    )
  }

  renderCompanyDetails(kind) {
    return (
      kind === 'billing' &&
      (
        <div className="address-company-details-container">
          <h3 className="address-company-details-title">Company Details (optional)</h3>
          { this.textInput('Company Name', 'company_name', this.state.company_name) }
          { this.textInput('Vat Number', 'vat_number', this.state.vat_number) }
        </div>
      )
    )
  }

  render() {
    const kind = this.props.kind

    return (
      <div>
        <h3 className="address-title">{`${kind} address`}</h3>

        <input id={`${this.props.addressOwner}_${kind}_info_attributes_kind`}
               value={kind}
               type="hidden"
               name={`${this.props.addressOwner}[${kind}_info_attributes][kind]`} />

        <input id={`${this.props.addressOwner}_${kind}_info_attributes_from_form`}
               value={true}
               type="hidden"
               name={`${this.props.addressOwner}[${kind}_info_attributes][from_form]`} />

        { this.textInput('First Name', 'first_name', this.state.first_name) }

        { this.textInput('Last Name', 'last_name', this.state.last_name) }

        { this.textInput('Address, line 1', 'address_1', this.state.address_1) }

        { this.textInput('Address, line 2', 'address_2', this.state.address_2) }

        { this.textInput('Postal Code', 'postal_code', this.state.postal_code) }

        { this.textInput('City', 'city', this.state.city) }

        { this.textInput('Phone Number', 'phone_number', this.state.phone_number) }

        { this.renderCountryField() }

        { this.renderStateField() }

        { this.renderCompanyDetails(kind) }
      </div>
    )
  }
}

Address.propTypes = {
  kind: PropTypes.string.isRequired,
  address: PropTypes.object.isRequired,
  countriesList: PropTypes.array.isRequired,
  usStatesList: PropTypes.array.isRequired,
  canadaStatesList: PropTypes.array.isRequired,
  errors: PropTypes.object
}

export default Address
