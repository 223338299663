import React from "react"

class CountriesSelect extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isListOpen: false,
      location: this.props.location
    }

    this.toggleList = this.toggleList.bind(this)
    this.selectItem = this.selectItem.bind(this)
  }

  toggleList() {
    this.setState({
      isListOpen: !this.state.isListOpen
    })
  }

  selectItem(item) {
    this.setState({
      location: item,
      isListOpen: false,
    })

    this.props.toggleLocation(item)
  }

  render() {
    const { isListOpen, location } = this.state
    const { countries } = this.props

    return (
      <div className="confirm-countries">
        <div className={`confirm-countries-location ${isListOpen ? "open" : ""}`} onClick={this.toggleList}>
          <div className="confirm-countries-location-container">
            <div className="confirm-countries-location-flag">
              <img
                src={require(`../../images/flags/${location.abbr.toLowerCase()}.svg`)}
                alt={`${location.name} flag`}
              />
            </div>
            <div className="confirm-countries-location-name">
              <div>
                <p>{location.name}</p>
                <p>({location.currency.symbol} {location.currency.code})</p>
              </div>
            </div>
          </div>
          <div
            className={`confirm-countries-location-button ${
              isListOpen ? "arrow-select-up" : "arrow-select-down"
            }`}
          ></div>
        </div>
        {isListOpen ? (
          <div role="list" className="confirm-countries-items">
            {countries.map((country) => (
              <div key={country.id} className="confirm-countries-item">
                <div className="confirm-countries-item-flag">
                  <img
                    src={require(`../../images/flags/${country.abbr.toLowerCase()}.svg`)}
                    alt={`${country.name} flag`}
                  />
                </div>
                <button
                  type="button"
                  className="confirm-countries-item-button"
                  key={country.id}
                  onClick={() => this.selectItem(country)}
                >
                  <span>{country.name}</span>
                  <span>{`(${country.currency.symbol} ${country.currency.code})`}</span>
                </button>
              </div>
            ))}
          </div>
        ) : null}
      </div>
    )
  }
}

export default CountriesSelect
