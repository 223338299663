import React from 'react'
import CountriesSelect from './CountriesSelect'

class ConfirmLocationWidget extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      confirmed: false,
      widget: true,
      selectedCountry: this.props.location
    }

    this.toggleLocation = this.toggleLocation.bind(this)
    this.handleConfirm = this.handleConfirm.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  handleConfirm() {
    this.handleContainerClose()
    this.setState({ confirmed: true })

    const locationAbbr = this.state.selectedCountry.abbr
    const url = new URL(window.location.href)
    url.searchParams.append('chosen_country', locationAbbr)
    window.location.href = url.href
  }

  toggleLocation(newLocation) {
    this.setState({ selectedCountry: newLocation })
  }

  handleClose() {
    this.handleContainerClose()
    this.setState({ widget: false })
  }

  handleContainerClose() {
    const containerElement = document.getElementById("checkout-confirm-location-widget-container")
    containerElement.style.display = 'none'
  }

  render() {
    const { confirmed, widget } = this.state
    const { countries, location } = this.props
    return (
      <div className="confirm-location-widget">
        {!confirmed && widget ? (
          <div className="confirm-location-widget-container">
            <div className="confirm-location-main-container">
              <div className="confirm-location-message-container">
                <div className="confirm-location-message">
                  <h3>Confirm your location</h3>
                  <p>Choose another country to see content specific to your location</p>
                </div>
              </div>
              <div className="confirm-location-select">
                <CountriesSelect
                  location={location}
                  countries={countries}
                  toggleLocation={this.toggleLocation}
                />
                <button
                  onClick={this.handleConfirm}
                  className="confirm-location-button"
                >
                  Continue
                </button>
              </div>
            </div>
            <button
              className="confirm-location-cross"
              onClick={this.handleClose}
            ></button>
          </div>
        ): null}
      </div>
    )
  }
}

export default ConfirmLocationWidget
