import React from 'react'
import PropTypes from 'prop-types'
import csrfToken from '../../src/csrf_token'

class PromoCodeContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      error: this.props.error,
      isCouponApplied: this.props.isCouponApplied,
      isDropDownOpen: this.props.error || this.props.isCouponApplied,
      couponValue: '',
      notice: null
    }

    this.toggleDropDown = this.toggleDropDown.bind(this)
    this.applyPromoCode = this.applyPromoCode.bind(this)
  }

  toggleDropDown() {
    if (this.state.isDropDownOpen === true) {
      this.setState({
        isDropDownOpen: false
      })
    } else {
      this.setState({
        isDropDownOpen: true
      })
    }
  }

  handleChange(event) {
    this.setState({
      couponValue: event.target.value
    })
  }

  applyPromoCode() {
    Mess.publish('disablePayment')
    $.ajax({
      url: '/checkout/transition',
      dataType: 'json',
      method: 'post',
      headers: {
        "X-CSRF-Token": csrfToken()
      },
      data: {
        transition: 'apply_coupon',
        order: {
          coupon_code: this.state.couponValue
        }
      },
      success: function(response) {
        Mess.publish('refresh_cart', response)
        this.setState({
          isCouponApplied: response.cart.couponName ? true : false,
          error: null,
          notice: response.notice
        })
      }.bind(this),
      error: function(data) {
        this.setState({
          error: data.responseJSON.error
        })
        Mess.publish('refresh_cart')
      }.bind(this)
    })
  }

  render() {
    const arrowClass = this.state.isDropDownOpen ? '--arrowUp' : '--arrowDown'
    const { error, notice } = this.state
    const errorClass = error ? 'red-border' : ''

    return (
      <div className="PromoCodeContainer">
        <div className="PromoCodeContainer-header">
          Coupon Code
        </div>

        <div className="PromoCodeContainer-body">
          <div className="PromoCodeContainer-body-drop">
            <span className="PromoCodeContainer-body-drop-text">Do you have a coupon code?</span>
            <span onClick={this.toggleDropDown} className={`PromoCodeContainer-body-drop${arrowClass}`}></span>
          </div>

          {
            this.state.isDropDownOpen && (
              <div>
                <div className="PromoCodeContainer-body-promoLable">
                  Enter Coupon Code:
                </div>
                {
                  error && (
                    <div className="error-container">
                      <div className="error-text">
                        { error }
                      </div>
                    </div>
                  )
                }
                {
                  (!error && this.state.isCouponApplied) && (
                    <div className="message-success">
                      Your coupon code has been successfully applied
                    </div>
                  )
                }
                <div className="PromoCodeContainer-body-inputRow">
                  <input className={"PromoCodeContainer-body-inputRow-input " + errorClass}
                         type="text"
                         value={this.state.couponValue}
                         onChange={e => this.handleChange(e)} />

                  <button className="PromoCodeContainer-body-inputRow-inputBtn"
                          onClick={this.applyPromoCode}>
                    Apply
                  </button>
                </div>
                {
                  notice && (
                    <div className="notice-container">
                      <div className="notice-text">
                        { notice }
                      </div>
                    </div>
                  )
                }
              </div>
            )
          }
        </div>
      </div>
    )
  }
}

PromoCodeContainer.propTypes = {
  error: PropTypes.array,
  isCouponApplied: PropTypes.bool.isRequired
}

export default PromoCodeContainer
