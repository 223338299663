import React from 'react'
import PropTypes from 'prop-types'

class CartTotal extends React.Component {
  render() {
    return (
      <div className='total'>
        <div className='total-label'>Total</div>
        <div className='total-cash'>
          { this.props.total }
        </div>
        <div className='discount-info'>
          Qualifying discounts will be applied automatically. Virtual Cash and coupon codes may be applied after proceeding to Checkout.
        </div>
        {
          {
            'login': <a className="btn-secure-checkout button-plain" href="/auth/login?purchase=1"><p className='lock'></p>Secure Checkout</a>,
            'proceed': <button type="submit" name="proceed" value="Secure Checkout" className="btn-secure-checkout button-plain" data-layer={ this.props.layer }><p className='lock'></p>Secure Checkout</button>,
            'braintree': <button type="submit" name="braintree" value="Secure Checkout" className="btn-secure-checkout button-plain" data-layer={ this.props.layer }><p className='lock'></p>Secure Checkout</button>,
            'checkout': <a className="btn-secure-checkout button-plain" href="/checkout" data-layer={ this.props.layer }><p className='lock'></p>Secure Checkout</a>
          }[this.props.action]
        }
        <hr className='line'/>
        <div className='payment-methods-icons'>
          <p className="payment-method-visa"></p>
          <p className="payment-method-klarna"></p>
          <p className="payment-method-mastercard"></p>
          <p className="payment-method-americanexpress"></p>
          <p className="payment-method-paypal"></p>
        </div>
        <a href="https://help.pluginboutique.com/hc/en-us/articles/6232309686164-Purchasing-Payments-FAQ" className="discount-info discount-info-bottom" target="_blank" rel="noopener noreferrer">
          More payment information.
        </a>
      </div>
    )
  }
}

CartTotal.propTypes = {
  total: PropTypes.string.isRequired,
  action: PropTypes.string,
  layer: PropTypes.string
};

export default CartTotal
