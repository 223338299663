import React from 'react'
import ProcessLine from './ProcessLine'
import SearchForm from './SearchForm'
import SearchResults from './SearchResults'
import csrfToken from '../src/csrf_token'

class SearchBox extends React.Component {
    state = {
        visible: false,
        processing: false,
        querydata: '',
        results_visible: false,
        filter: this.props.filter
    };

    querydata = '';

    componentDidMount() {
        Mess.subscribe('toggle_search', this.toggle_search)
    }

    toggle_search = () => {
        this.setState({ visible: !this.state.visible })
    };

    handle_query_change = (query) => {
        if (query != this.state.querydata) {
            this.querydata = query
            this.search_results()
        }
    };

    handleFilterChange = (filters) => {
        // this.setState({ filters: filters })
        // this.searchResults()
    };

    search_results = () => {
        clearTimeout(this.timer)
        this.timer = setTimeout(this.search_results_process, 300)
    };

    search_results_process = () => {
        if (this.xhr) {
            this.xhr.abort()
        }

        this.setState({ processing: true })

        this.xhr = $.ajax({
            url: '/search_hints',
            data: this.querydata, //  { q: this.state.query, filters: this.state.filters },
            dataType: 'json',
            headers: {
              "X-CSRF-Token": csrfToken()
            },
            success: function(data) {
                if (data["data_layer_event"]) {
                  dataLayer.push(JSON.parse(data["data_layer_event"]))
                }

                this.setState(
                  { results: data["items"], processing: false, results_visible: data["items"].length > 0 }
                )
            }.bind(this),
            error: function(xhr, status, err) {
                console.error(this.props.url, status, err.toString());
                this.setState({ processing: false })

            }.bind(this)
        });

    };

    handle_hide_results = (event) => {
        this.setState({ results_visible: false, visible: false })
        if (event) {
            event.preventDefault()
        }
    };

    render() {
        const searchBar = document.querySelector('#search-bar')
        searchBar.style.display = 'none'

        var clazz = "searchbox" + (this.state.visible ? ' _visible' : '')

        return (
            <div className={ clazz }>
                <ProcessLine processing={ this.state.processing } />
                <SearchForm query={ this.props.query } filter={ this.state.filter } onChange={ this.handle_query_change } on_close={ this.handle_hide_results } closable={ this.state.results_visible } />
                { this.state.results_visible ? <SearchResults results={ this.state.results } /> : null }
            </div>
        )
    }
}

export default SearchBox
