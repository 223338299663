export default function trackProductTileClick (clickEvent) {
  if (!window.$TB) return

  const tile = clickEvent.target.closest('.producttile')
  if (!tile) return

  const frId = tile.dataset.frId
  const href = tile.querySelector('.producttile-title > a')?.href
  const meta = clickEvent.target.closest('[data-fr-meta]')?.dataset?.frMeta
  if (!frId || !href || !meta) return

  const parsedMeta = JSON.parse(meta)

  window.$TB.trackClick(
    parsedMeta,
    URL.canParse(href) ? href : window.location.origin + href,
    frId
  )
}