import React from 'react'
import PropTypes from 'prop-types'
import SidebarCartItem from './SidebarCartItem'
import csrfToken from '../../src/csrf_token'
import SidebarCartSubItem from "./SidebarCartSubItem";

class SidebarCartContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      vatSwitcherValue: false,
      cart: this.props,
      arrowClass: 'arrowDown',
      expandItemsClass: 'SidebarCartContainer-hidden',
      couponRemoveDisabled: false,
      vcRemoveDsabled: false,
      selectedGift: this.props.selectedGift
    }

    this.handleSwitcherValueChange = this.handleSwitcherValueChange.bind(this)
    this.refreshCart = this.refreshCart.bind(this)
    this.refreshWishlist = this.refreshWishlist.bind(this)
    this.expandContractItems = this.expandContractItems.bind(this)
    this.removePromoCode = this.removePromoCode.bind(this)
    this.removeVc = this.removeVc.bind(this)
  }

  componentDidMount() {
    Mess.subscribe('refresh_wishlist', this.refreshWishlist)
    Mess.subscribe('refresh_cart', this.refreshCart)
  }

  refreshWishlist(data) {
    this.setState({
      cart: data.cart
    })
  }

  refreshCart(data) {
    if (!data) return

    this.setState({
      cart: data.cart,
      selectedGift: data.cart.selectedGift,
      couponRemoveDisabled: false,
      vcRemoveDsabled: false
    })
  }

  handleSwitcherValueChange() {
    if (this.state.arrowClass === 'arrowDown') {
      this.expandContractItems()
    }

    this.setState({
      vatSwitcherValue: !this.state.vatSwitcherValue
    })
  }

  expandContractItems() {
    if (this.state.arrowClass === 'arrowUp') {
      this.setState({
        arrowClass: 'arrowDown',
        expandItemsClass: 'hidden'
      })
    } else {
      this.setState({
        arrowClass: 'arrowUp',
        expandItemsClass: ''
      })
    }
  }

  removePromoCode() {
    if (this.state.couponRemoveDisabled === false) {
      Mess.publish('disablePayment')
      $.ajax({
        url: '/checkout/transition',
        dataType: 'json',
        method: 'post',
        headers: {
          "X-CSRF-Token": csrfToken()
        },
        data: {
          transition: 'apply_coupon',
          order: {
            coupon_code: ''
          }
        },
        success: function(response) {
          Mess.publish('refresh_cart', response)
          this.setState({
            cart: response.cart
          })
        }.bind(this),
        error: function(error) {
          console.log(error)
        }.bind(this)
      })

      this.setState({
        couponRemoveDisabled: true
      })
    }
  }

  removeVc() {
    if (this.state.vcRemoveDsabled === false) {
      Mess.publish('disablePayment')
      $.ajax({
        url: '/checkout/transition',
        dataType: 'json',
        method: 'post',
        headers: {
          "X-CSRF-Token": csrfToken()
        },
        data: {
          transition: 'apply_vc',
          order: {
            vc_spent: '0'
          }
        },
        success: function(response) {
          Mess.publish('refresh_cart', response)
          this.setState({
            cart: response.cart
          })
        }.bind(this),
        error: function(data) {
          console.log(data)
        }.bind(this)
      })

      this.setState({
        vcRemoveDsabled: true
      })
    }
  }

  cartItem = (item) => {
    const { fallbackImageSource, rto, orderId, subscription } = this.props

    if (subscription) {
      return <SidebarCartSubItem key={item.order_item_id}
                                 item={item}
                                 fallbackImageSrc={fallbackImageSource}
                                 vatSwitcherValue={this.state.vatSwitcherValue}
                                 subscriptionId={orderId} />
    } else {
      return <SidebarCartItem key={item.order_item_id}
                              item={item}
                              fallbackImageSrc={fallbackImageSource}
                              vatSwitcherValue={this.state.vatSwitcherValue}
                              isGift={false}
                              subscriptionId={rto && orderId} />
    }
  }

  trialSection() {
    const trial = this.props.trial

    if (!trial) return

    if (trial.availableForUser) {
      return (
        <div className='SidebarCartContainer-trialInfo'>
          <div className="SidebarCartContainer-trialInfo-header">Details</div>
          <div className="SidebarCartContainer-trialInfo-text">
            <span>Beatport Studio comes with a</span>
            <span> {trial.trialInDays} </span>
            <span> Day Free Trial. After your</span>
            <span> {trial.trialInDays} </span>
            <span> Day Free Trial, you will be charged</span>
            <span className='SidebarCartContainer-trialInfo-text-highlight'> {trial.subscriptionPeriodPart} </span>
            <span>unless you cancel. Your first payment will be on</span>
            <span className='SidebarCartContainer-trialInfo-text-highlight'> {trial.billingDate} </span>
          </div>
        </div>
      )
    } else {
      return (
        <div className='SidebarCartContainer-trialInfo'>
          <div className="SidebarCartContainer-trialInfo-header">Details</div>
          <div className="SidebarCartContainer-trialInfo-text">
            <span className='SidebarCartContainer-trialInfo-text-redHighlight'>A Beatport Studio Free Trial has already been used on this IP address.</span>
            <span> You will be charged</span>
            <span className='SidebarCartContainer-trialInfo-text-highlight'> {trial.subscriptionPeriodPart} </span>
            <span>unless you cancel.</span>
          </div>
        </div>
      )
    }
  }

  render() {
    const cart = this.state.cart
    const gift = this.state.selectedGift
    const couponValue = cart.couponPercentsValue !== null ? `-${cart.couponPercentsValue}% (${cart.couponValue})` : `-${cart.couponValue}`
    const editCartClass = this.props.subscription ? 'SidebarCartContainer-header-secondRow-edit-hidden' : ''

    return (
      <div className="SidebarCartContainer-allContent">
        <div className="SidebarCartContainer-wrapper">
          <div className="SidebarCartContainer-header">
            <div className="SidebarCartContainer-header-firstRow">
              <div className={"SidebarCartContainer-header-firstRow-title " + (this.props.subscription ? "sub" : "")}>{this.props.cartTitle || 'Cart'}</div>
              <div className={`SidebarCartContainer-header-firstRow--${this.state.arrowClass}`} onClick={this.expandContractItems}></div>
            </div>
            <div className="SidebarCartContainer-header-secondRow">
              <a href="/cart" className={`SidebarCartContainer-header-secondRow-edit ${editCartClass}`}>Edit Cart</a>
              { !this.props.subscription && <div className="SidebarCartContainer-header-secondRow-bag">
                <span className="SidebarCartContainer-header-secondRow-bag--items">{cart.cartItems.length} item(s)</span>
              </div> }
              { cart.isVatApplicable && (
                <div className="SidebarCartContainer-header-secondRow-vat">
                  Show Tax
                  <label className="switch">
                    <input type="checkbox"
                           defaultChecked={this.state.vatSwitcherValue}
                           onChange={this.handleSwitcherValueChange} />
                    <span className="slider round"></span>
                  </label>
                </div>
              )}
            </div>
          </div>
          <div className={`SidebarCartContainer-productsList ${this.state.expandItemsClass}`}>
            { cart.cartItems.map(item => this.cartItem(item)) }

            {
              gift && (
                <SidebarCartItem key={gift.id}
                                 item={gift}
                                 fallbackImageSrc={this.props.fallbackImageSource}
                                 vatSwitcherValue={this.state.vatSwitcherValue}
                                 isGift={true} />
              )
            }
          </div>
          { (cart.tokensWillEarn || cart.virtualCash) && <div className={`SidebarCartContainer-tokensVc ${this.state.expandItemsClass}`}>
            { cart.tokensWillEarn && <div className="SidebarCartContainer-tokensVc-tokens">
              <span className="SidebarCartContainer-tokensVc-tokens-text">Tokens You Will Earn</span>
              <span>{cart.tokensWillEarn}</span>
            </div> }
            { cart.virtualCash &&<div className="SidebarCartContainer-tokensVc-vc">
              <span className="SidebarCartContainer-tokensVc-vc-text">Virtual Cash You Will Earn</span>
              <span>{cart.virtualCash}</span>
            </div> }
          </div> }
          { cart.couponName && (
            <div className="SidebarCartContainer-coupon">
              <div onClick={this.removePromoCode} className="SidebarCartContainer-coupon-cross"></div>
              <span className="SidebarCartContainer-coupon-code">{cart.couponName}</span>
              <span className="SidebarCartContainer-coupon-value">{couponValue}</span>
            </div>
          )}

          { cart.vcSpent && parseFloat(cart.vcSpent.substring(1)) !== 0 && (
            <div className="SidebarCartContainer-coupon">
              <div onClick={this.removeVc} className="SidebarCartContainer-coupon-cross"></div>
              <span className="SidebarCartContainer-coupon-code">Virtual Cash Applied</span>
              <span className="SidebarCartContainer-coupon-value">-{cart.vcSpent}</span>
            </div>
          )}
          { this.props.subscription && (
            <div className="SidebarCartContainer-total">
              <div className="SidebarCartContainer-subscriptionPeriod">
                <span>{this.props.subscriptionPeriod}</span>
              </div>
            </div>
          )}
          <div className={`SidebarCartContainer-subtotalVat ${this.state.expandItemsClass} ${cart.isVatApplicable && this.state.vatSwitcherValue === true ? '' : 'hidden'}`}>
            <div className="SidebarCartContainer-subtotalVat-total">
              <span>Subtotal</span>
              <span>{cart.total}</span>
            </div>
            <div className="SidebarCartContainer-subtotalVat-vat">
              <span>TAX ({cart.vatRate}%)</span>
              <span>{cart.vatValue}</span>
            </div>
          </div>
          <div className="SidebarCartContainer-total">
            <span className="SidebarCartContainer-total--text">{this.props.subscription ? 'Pay now' : 'Total'}</span>
            <span className="SidebarCartContainer-total--price">
              {cart.totalWithVat}
            </span>
          </div>
          { this.trialSection() }
          <div className="SidebarCartContainer-secure">
            <span className='SidebarCartContainer-secure-lock'></span><span className="SidebarCartContainer-secure-text">Secure Checkout</span>
          </div>
        </div>
      </div>
    )
  }
}

SidebarCartContainer.propTypes = {
  fallbackImageSource: PropTypes.string.isRequired,
  orderId: PropTypes.number.isRequired,
  userName: PropTypes.string,
  tokensWillEarn: PropTypes.string,
  virtualCash: PropTypes.string,
  total: PropTypes.string.isRequired,
  totalWithVat: PropTypes.string.isRequired,
  wishlist: PropTypes.object,
  isVatApplicable: PropTypes.bool.isRequired,
  cartItems: PropTypes.array.isRequired,
  vatValue: PropTypes.string,
  selectedGift: PropTypes.object,
  couponPercentsValue: PropTypes.number,
  couponValue: PropTypes.string,
  couponName: PropTypes.string,
  rto: PropTypes.bool,
  subscription: PropTypes.bool,
  cartTitle: PropTypes.string,
  subscriptionPeriod: PropTypes.string
}

export default SidebarCartContainer
