import React from "react"
import csrfToken from "../src/csrf_token"

class CurrencyChanger extends React.Component {
  state = {
    currency: (this.props.currency || "GBP").toUpperCase(),
    progress: false,
  }

  handleCountryChange = (code, event) => {
    if (this.props.justLinks) {
      return
    }

    event.stopPropagation()
    event.preventDefault()

    this.setState({ currency: code })
    this.sendNewLanguage(code)
  }

  sendNewLanguage = (code) => {
    this.setState({ progress: true })
    $.ajax({
      url: "/cart?currency=" + code,
      dataType: "json",
      headers: {
        "X-CSRF-Token": csrfToken(),
      },
      success: function (data) {
        Mess.publish("refresh_cart", data)
        this.setState({ progress: false })
        Mess.publish("currency_changed", code)
        if (this.props.callback) {
          this.props.callback(code)
        }
      }.bind(this),
      error: function (xhr, status, err) {
        console.error(this.props.url, status, err.toString())
        this.setState({ progress: false })
      }.bind(this),
    })
  }

  render() {
    const symbols = { GBP: "£", USD: "$", EUR: "€", JPY: "¥" }
    const hints = {
      GBP: "Change currency to United Kingdom Pound",
      EUR: "Change currency to Euro",
      JPY: "Change currency to Japan Yen",
      USD: "Change currency to United States Dollar",
    }

    return (
      <div className="currencychooser">
        {
          Object.entries(symbols).map(([code, symbol]) => {
            const { progress, currency } = this.state
            const isSelected = code === currency
            const className = isSelected ? "--selected" + (progress ? " --progress" : "") : ""
            return (
              <a
                href={"?currency=" + code}
                key={code}
                className={className}
                title={hints[code]}
                onClick={this.handleCountryChange.bind(this, code)}
              >
                {isSelected ? `${symbol} ${code}` : symbol}
              </a>
            )
          })
        }
      </div>
    )
  }
}

export default CurrencyChanger
